.splashscreen {
    position: relative;
    height: 100%;
    &_image {
        width: 100%;
        @media screen and (min-width: 600px){
            width: 70%;
        }
        &Curve {
            position: absolute;
            bottom: -3px;
            left: 0;
            height: auto;
            z-index: 1;
    
            path {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
    &_footer {
        position: absolute;
        font-size: 16px;
        bottom: 0px;
        width:100%;
        &_logo {
            height: 20px;
        }
    }

}