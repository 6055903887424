.mainForm {
    height: 100vh;
    background-color: white;
}

.section { 
    background-color:rgb(255, 236, 243)
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: rgb(255, 236, 243); // Choose your own color here
 }
 .table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color:white;
}
