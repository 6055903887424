// Bootstrap overrides

// Colour variables
$primary: #442697;
$white:   #fff;

$body-bg:   #e9ecef;

$font-family-sans-serif: 'Montserrat', sans-serif;
$headings-font-family: 'Roboto Slab', serif;
